// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rsvp-js": () => import("./../src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-templates-year-entry-js": () => import("./../src/templates/year-entry.js" /* webpackChunkName: "component---src-templates-year-entry-js" */)
}

